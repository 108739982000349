import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout/default-layout';
import { SecureLayoutComponent } from './layout/secure-layout';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./views/website/routes').then((m) => m.routes)
  },
  {
    path: 'auth',
    component: DefaultLayoutComponent,
    loadChildren: () => import('./views/public/auth/routes').then((m) => m.routes)
  },
  {
    path: 'secure',
    component: SecureLayoutComponent,
    loadChildren: () => import('./views/secure/routes').then((m) => m.routes)
  },
  {
      path: 's',
      loadChildren: () => import('./views/secure/routes.sms').then((m) => m.routes)
      // canActivate: [AuthGuard],
      // data: {
      //     title: $localize`Dashboard`,
      //     roles: [UserRole.Any]
      // }
  },
  { path: '**', redirectTo: 'home' }
    ];

